import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../Assets/logo-header.svg";
import LogoutModal from "../../Components/Modals/LogoutModal/LogoutModal";
import "./HeaderOrg.css";

const HeaderOrg = () => {
    const currencyDetails = useSelector(state=>state.currencyReducer)
    const fname = localStorage.getItem("userFirstName");

    const logoutHandler = (e) => { e.preventDefault() }
    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    return (
        <>
            <>
                {["sm"].map((expand) => (
                    <Navbar
                        key={expand}
                        expand={expand}
                        className="parent-header"
                        style={{ backgroundColor: "#797979" }}
                    >
                        <div className="parent-header-container d-flex justify-content-between flex-grow-1 mx-3">
                            {/* <Navbar.Brand className="d-flex flex-grow-1"> */}
                            <a href="/organizer-dashboard"><img className="feis-logo" src={logo} alt="logo" /></a>
                            {/* </Navbar.Brand> */}

                            {localStorage.getItem("accessToken") && (
                                <>
                                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}/>
                                    <Navbar.Offcanvas
                                        id={`offcanvasNavbar-expand-${expand}`}
                                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                        className="parent-mobile-nav"
                                        placement="end"
                                    >
                                        <Offcanvas.Body>
                                            <div className="nav-parent-org">
                                                <Nav className="navbar-parent">
                                                    <NavLink className="navbar-dashboard" to="/organizer-dashboard" exact="true">
                                                        Dashboard
                                                    </NavLink>
                                                    {/* <div className="navbar-dashboard" style={{ display: "flex", alignItems: "center", marginRight: "8px" }}>
                                                    Region:
                                                    {currencyDetails?.country ? (
                                                    <img
                                                        // src={`https://flagsapi.com/${currencyDetails?.country}/flat/64.png`}
                                                        src={`https://flagcdn.com/256x192/${currencyDetails.country.toLowerCase()}.png`}
                                                        alt="flag"
                                                        style={{ marginLeft: "8px", height: "20px" }}
                                                    />
                                                    ) : null}
                                                    </div> */}
                                                    <NavDropdown
                                                        title={fname}
                                                        className="dropdown dropdown-org"
                                                        id={`offcanvasNavbarDropdown-expand-${expand}`}
                                                    >
                                                        <NavLink
                                                            className="menu"
                                                            style={{ cursor: "pointer", fontSize: "" }}
                                                            to="/my-account-org"
                                                            exact="true"
                                                        >
                                                            My account
                                                        </NavLink>
                                                        <NavLink className="menu" onClick={logoutHandler}>
                                                            <LogoutModal name="Sign out" />
                                                        </NavLink>
                                                    </NavDropdown>
                                                </Nav>
                                            </div>
                                        </Offcanvas.Body>
                                    </Navbar.Offcanvas>
                                </>
                            )}
                        </div>
                    </Navbar>
                ))}
            </>
        </>
    );
};

export default HeaderOrg;
