import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckOutFormGrade from "../../Parent/Payments/CheckOutFormGrade";
import "./style.css";

const publishedKey = `pk_test_wP7nnS43HzGxunFRs7MEXqpY009anuTPeL`;
const stripePromise = loadStripe(publishedKey);

const PaymentGrade = ({ client, from }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch("/config").then(async (e) => {
      setClientSecret(`${client}`);
    });
  }, [client]);

  return (
    <div className="col-md-8 offset-md-2 offset-1 col-10">
      <div className="creditCard">Payment method </div>
      <hr className="mt-0 " />

      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <div className="stripePage">
            <CheckOutFormGrade from={from} />
          </div>
        </Elements>
      )}
    </div>
  );
};

export default PaymentGrade;
