import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import feisLogo from "../Assets/feis-logo.svg";
import AddDancerModal from "../Components/Modals/AddDancerModal";
import Loader from "../Components/Loader/Loader";
import EditIcon from "@mui/icons-material/Edit";
import EditDancerModal from "../Components/Modals/EditDancerModal";
import Help from "../Components/Help/Help";
import "./Auth/style.css"
import { serviceConsumer } from "../network/ServiceConsumer";
import AddButton from "../Components/AddButton/AddButton";

const AddDancers = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setopenModalEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [allDancer, setAllDancer] = useState([]);
  const [dancer, setDancer] = useState();
  const [editDancerDetails, setEditDancerDetails] = useState([]);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const getAllEvents = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/account/dancers`;
    try {
      const response = await serviceConsumer("GET", url)
      setAllDancer(response.dancers)
    } catch (error) {
      setLoading(false);
    }
  };

  const editIconClickHandler = (dancer) => {
    setopenModalEdit(!openModalEdit);
    setDancer(dancer);
    let allDancerArr = [...allDancer];
    const filtereddancer = allDancerArr.filter((dancerId) => {
      if (dancerId._id === dancer._id) {
        setEditDancerDetails(dancerId);
      }
    });
  };

  const DeleteDancer = async (id) => {
    setLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/account/dancers/${id}`;
      const response = await serviceConsumer("DELETE", url);
      getAllEvents();
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEvents();
  }, [editDancerDetails]);

  useEffect(() => {
    allDancer.length > 0 ? setBtnDisabled(false) : setBtnDisabled(true);
  }, [allDancer]);

  const UpdateDancer = (status = false) => {
    if (status) getAllEvents();
  };

  const actionEdit = () => {
    setopenModalEdit(!openModalEdit);
  };

  return (
    <div className="d-flex flex-column justify-content-between mb-5" style={{ minHeight: "100vh" }} >
      {loading && <Loader />}
      <div className="container">
        <img src={feisLogo} alt="" className="m-4 cursor-pointer feis-logo" onClick={() => { navigate("/"); }} />
      </div>
      <div className="d-flex justify-content-center">
        <div className="login-dancer">
          <h3 className="grey-32">Add dancers</h3>
          <p className="m-0 mb-5 grey-32">Complete with the Dancer's information.</p>
          {allDancer.length > 0
            ? allDancer.slice(0, 5).map((dancer) => {
              return (
                <CardContent variant={"h4"} key={dancer._id} className="allDancers">
                  <button style={{ border: "none", background: "inherit", float: "right", display: "flex", gap: "5px", }}>
                    <DeleteOutlineOutlinedIcon
                      className="deleteIcon-dancer "
                      onClick={() => DeleteDancer(dancer._id)}
                    />
                    <EditIcon
                      className="editIcon-dancer" alt="edit-icon"
                      onClick={() => { editIconClickHandler(dancer); }}
                    />
                  </button>
                  <Typography sx={{ fontSize: "16px", color: " #424242", overflowWrap: "anywhere", }}>
                    {dancer.fname} {dancer.lname}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: "12px !important", color: " #000000", opacity: "0.2 ", }} >
                    {dancer.levels}
                  </Typography>
                </CardContent>
              );
            })
            : ""}
          {allDancer.length < 5 &&
            <AddButton className="plusIconbtn mt-5" onClick={handleOpenModal}>
              {allDancer.length > 0 ? "Add dancers" : "Add dancer"}
            </AddButton>
          }
          {allDancer.length >= 5 &&
            <AddButton className="updateChangeDisabled mt-5">
              Add dancer
            </AddButton>
          }
          <button className={btnDisabled ? "disabled mt-4" : "filled mt-4"} onClick={() => setTimeout(() => navigate("/"), 1000)}>
            Complete registration
          </button>

          <button className="text-btn mt-4" onClick={() => setTimeout(() => navigate("/"), 1000)}>
            Skip
          </button>
        </div>
      </div>
      {dancer && (
        <EditDancerModal
          modalState={openModalEdit}
          edit={editIconClickHandler}
          action={actionEdit}
          dancer={dancer}
          getAllEvents={getAllEvents}
          editDancerDetails={editDancerDetails}
        />
      )}
      <AddDancerModal
        modalState={openModal}
        action={handleOpenModal}
        UpdateDancer={UpdateDancer}
      />
      <Help />
    </div>
  );
};

export default AddDancers;
